import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import styled from 'styled-components';
import html2canvas from 'html2canvas';

import RoomEditorTopMenu from '../components/room/RoomEditorTopMenu';
import RoomEditorBottomMenu from '../components/room/RoomEditorBottomMenu';
import useDraggable from '../hooks/useDraggable';
import SelectionBox from '../components/room/SelectionBox';
import useItemSort from '../hooks/useItemSort';
import RoomEditorSidebar from '../components/room/RoomEditorSidebar';
import useToastModal from '@hooks/useToastModal';
import RoomItemListBottomSheet from '../components/room/RoomItemListBottomSheet';
import {
  myRoomCurrentMainCategoryState,
  myRoomCurrentSubCategoryState,
  myRoomFieldSizeState,
  myRoomItemListBottomSheetScrollState,
  myRoomState,
  uniqueCartRoomItemsState,
} from '@states/myRoomState';
import useMyRoomDetailQuery from '../hooks/useMyRoomDetailQuery';
import { useParams } from 'react-router-dom';
import MyRoomEditorSkeleton from '../components/skeleton/MyRoomEditorSkeleton';
import CartRoomItemBottomSheet from '../components/room/CartRoomItemBottomSheet';
import useModal from '@hooks/useModal';
import SpeechBubble from '../components/room/SpeechBubble';
import WriteBottomSheet from '../components/room/WriteBottomSheet';
import useEditorBackPressHandling from '@pages/my/hooks/useEditorBackPressHandling';
import { uploadCanvasToS3, uploadJsonToS3 } from '@modules/imgS3';
import useGetRoomJson from '../hooks/useGetRoomJson';
import { MyRoomJsonType } from '@models/myRoom';
import useAdBrix from '@hooks/adBrix/useAdBrix';
import SlimPopup from '@components/elements/Popup/SlimPopup';
import dayjs from 'dayjs';

const RoomEditorPage = () => {
  const { sendAdBrixData } = useAdBrix();
  const roomRef = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const sidebarItemRef = useRef<HTMLDivElement[]>([]);

  const resetItemListBottomSheetScroll = useResetRecoilState(myRoomItemListBottomSheetScrollState);
  const resetCurrentMainCategory = useResetRecoilState(myRoomCurrentMainCategoryState);
  const resetCurrentSubCategory = useResetRecoilState(myRoomCurrentSubCategoryState);
  const resetMyRoomState = useResetRecoilState(myRoomState);

  const { roomId } = useParams<{ roomId?: string }>();
  const {
    isLoading: isJsonLoading,
    isError: isJsonError,
    isAllJsonImageLoading,
  } = useGetRoomJson(roomId ?? '');
  const { cleanRoom, isLoading, isAllQueryImageLoading } = useMyRoomDetailQuery(
    roomId ?? '',
    isJsonLoading || !isJsonError,
  );
  const isAllLoading = useMemo(
    () => isJsonLoading || isLoading || isAllJsonImageLoading || isAllQueryImageLoading,
    [isAllJsonImageLoading, isJsonLoading, isLoading, isAllQueryImageLoading],
  );
  const [myRoom, setMyRoom] = useRecoilState(myRoomState);
  const uniqueCartRoomItems = useRecoilValue(uniqueCartRoomItemsState);
  const [myRoomFieldSize, setMyRoomFieldSize] = useRecoilState(myRoomFieldSizeState);

  const { openModal, closeModal } = useModal();
  const { openModal: openToast } = useToastModal();
  const [ratio, setRatio] = useState(1);
  const [originalRatio, setOriginalRatio] = useState(1);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [showSidebar, setShowSidebar] = useState(false);

  const isShortHeight = useMemo(
    () => window.innerHeight - myRoomFieldSize.height <= 136 + 60,
    [myRoomFieldSize.height],
  ); // top menu + bottom menu = 136, 위 패딩(20) + 하단 패딩(40) = 60

  const { onClickSortUp, onClickSortDown } = useItemSort({
    items: myRoom.items,
    selectedId: selectedItemId,
  });

  const addToSidebarItemRefs = (el: HTMLDivElement | null, index: number) => {
    if (el) {
      sidebarItemRef.current[index] = el;
    }
  };

  // 아이템 선택
  const onSelectItem = (id: string) => {
    setSelectedItemId(id);
  };

  // 아이템 삭제
  const onRemoveItem = (id: string) => {
    setMyRoom((prev) => ({
      ...prev,
      items: prev.items.filter((item) => item.id !== id),
    }));
    setSelectedItemId(null);
  };

  const onConfirmSpeechBubbleText = (id: string, text: string) => {
    setMyRoom((prev) => ({
      ...prev,
      items: prev.items.map((item) => (item.id === id ? { ...item, text } : item)),
    }));
  };

  // 말풍선 쓰기 바텀시트 오픈
  const onClickWrite = (id: string, initialText?: string) => {
    openModal(WriteBottomSheet, {
      onClose: () => {
        closeModal(WriteBottomSheet);
      },
      id,
      initialText,
      onConfirm: onConfirmSpeechBubbleText,
    });
  };

  const onMoveEnd = (id: string, x: number, y: number) => {
    setMyRoom((prev) => ({
      ...prev,
      items: prev.items.map((item) => (item.id === id ? { ...item, x, y } : item)),
    }));
  };

  // 한 칸 위로
  const onLayerUp = () => {
    const items = onClickSortDown();
    if (!items) return;
    setMyRoom((prev) => ({
      ...prev,
      items,
    }));
  };

  // 한 칸 아래로
  const onLayerDown = () => {
    const items = onClickSortUp();
    if (!items) return;
    setMyRoom((prev) => ({
      ...prev,
      items,
    }));
  };

  const onToggleSidebar = () => {
    setShowSidebar((prev) => !prev);
  };

  const handleResize = () => {
    if (!roomRef.current) return;
    const ROOM_WIDTH = roomRef.current.getBoundingClientRect().width ?? 0;
    const canvasWidth = 375;
    setRatio(ROOM_WIDTH / canvasWidth);
    setOriginalRatio(canvasWidth / ROOM_WIDTH);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ratio, originalRatio]);

  useEffect(() => {
    if (isAllLoading) return;
    handleResize();
  }, [isAllLoading]);

  // --- 아이템이나 메뉴 클릭이 아닌 외부 클릭할 경우 선택 해제 ---
  const handleOutsideClick = (e: TouchEvent) => {
    if (selectedItemId !== null || !showSidebar) {
      const clickedElement = e.target as HTMLElement;
      if (!clickedElement.closest('.draggable-item') && !clickedElement.closest('.editor-menu')) {
        setSelectedItemId(null);
        setShowSidebar(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('touchstart', handleOutsideClick);
    return () => {
      document.removeEventListener('touchstart', handleOutsideClick);
    };
  }, []);

  // --- 아이템 클릭 시 사이드 바 스크롤 이벤트 ---
  useEffect(() => {
    if (!showSidebar || !selectedItemId) return;

    const index = sidebarItemRef.current.findIndex(
      (item) => item.getAttribute('data-id') === selectedItemId,
    );

    if (index === -1) return;
    sidebarRef.current?.scrollTo({
      top: sidebarItemRef.current[index]?.offsetTop - (404 / 2 - 52 / 2),
      behavior: 'smooth',
    });
  }, [selectedItemId, myRoom.items, showSidebar]);

  // --- 방 비우기 ---
  const onCleanRoom = () => {
    // 기본 바닥, 벽지, 캐릭터로 초기화
    openModal(SlimPopup, {
      headText: '아이템을 삭제하고\n방을 모두 초기화할까요?',
      buttonText1: '아니요',
      buttonText2: '네',
      onClose: () => {
        closeModal(SlimPopup);
      },
      onClick: () => {
        cleanRoom();
        closeModal(SlimPopup);
        openToast({ children: <p>방을 깨끗이 청소했어요!</p> });
      },
    });
  };

  // --- 아이템 추가 ---
  const onClickRoomItemBottomSheet = () => {
    if (myRoom.items.length >= 50) {
      openToast({
        children: (
          <p>
            한 방 안에 아이템 배치는
            <br />
            50개까지만 가능해요
          </p>
        ),
      });
      return;
    }

    openModal(RoomItemListBottomSheet, {
      ratio,
      onClose: () => {
        closeModal(RoomItemListBottomSheet);
      },
    });
  };

  // --- 구매 필요 ---
  const onClickCartRoomItemBottomSheet = () => {
    openModal(CartRoomItemBottomSheet, {
      onClose: () => {
        closeModal(CartRoomItemBottomSheet);
      },
    });
  };

  // --- 룸 저장 ---
  const onSave = async () => {
    if (!roomRef.current) return;
    if (!myRoom.roomId) return;

    const room: MyRoomJsonType = {
      roomId: myRoom.roomId,
      roomName: myRoom.roomName,
      colorFloor: myRoom.colorFloor,
      updatedAt: dayjs().unix(),
      wallpaper: {
        id: myRoom.wallpaper.id,
        type: myRoom.wallpaper.type,
        room_item_idx: myRoom.wallpaper.room_item_idx,
        name: myRoom.wallpaper.name,
        img_origin: myRoom.wallpaper.img_origin,
        img_thumb: myRoom.wallpaper.img_thumb,
        img_details: myRoom.wallpaper.img_details,
        width: myRoom.wallpaper.width,
        height: myRoom.wallpaper.height,
      },
      floor: {
        id: myRoom.floor.id,
        type: myRoom.floor.type,
        room_item_idx: myRoom.floor.room_item_idx,
        name: myRoom.floor.name,
        img_origin: myRoom.floor.img_origin,
        img_thumb: myRoom.floor.img_thumb,
        img_details: myRoom.floor.img_details,
        width: myRoom.floor.width,
        height: myRoom.floor.height,
      },
      items: myRoom.items.map((item) => ({
        id: item.id,
        type: item.type,
        room_item_idx: item.room_item_idx,
        name: item.name,
        x: item.x,
        y: item.y,
        img_origin: item.img_origin,
        img_thumb: item.img_thumb,
        img_details: item.img_details,
        width: item.width,
        height: item.height,
        text: item.text || '',
      })),
    };

    const height = roomRef.current.offsetHeight;
    const width = roomRef.current.offsetWidth;
    roomRef.current.style.backgroundColor = myRoom.colorFloor;

    const imgElements = roomRef.current.querySelectorAll('img');

    Array.from(imgElements).forEach((img: HTMLImageElement) => {
      img.crossOrigin = 'Anonymous';
      img.src = img.src + '?timestamp=' + Date.now();
    });

    const canvas = await html2canvas(roomRef.current, {
      width,
      height,
      scale: 3,
      useCORS: true,
    });

    const dataUrl = canvas.toDataURL('image/png');
    await uploadCanvasToS3(dataUrl, room.roomId, 'room');
    await uploadJsonToS3({
      jsonData: room,
      name: room.roomId,
      path: 'room',
    });
  };

  // --- 안드로이드 백버튼 핸들러 ---
  useEditorBackPressHandling();

  // --- 마이룸 필드 사이즈 저장 --- (아이템 추가 시 가운데 배치를 위해 필요)
  useEffect(() => {
    if (!roomRef.current || isAllLoading) return;

    setMyRoomFieldSize({
      width: roomRef.current.clientWidth,
      height: roomRef.current.clientHeight,
    });
  }, [setMyRoomFieldSize, isAllLoading]);

  // --- 페이지 벗어나면 아이템 리스트 바텀 시트 스크롤, 카테고리 보고 있던 값들 초기화 ---
  useEffect(() => {
    return () => {
      resetCurrentMainCategory();
      resetCurrentSubCategory();
      resetItemListBottomSheetScroll();
      resetMyRoomState();
    };
  }, [
    resetCurrentMainCategory,
    resetCurrentSubCategory,
    resetItemListBottomSheetScroll,
    resetMyRoomState,
  ]);

  useEffect(() => {
    sendAdBrixData('customEvent', {
      eventKey: 'view_room_editor',
    });
  }, []);

  if (isAllLoading) {
    return <MyRoomEditorSkeleton padding={isShortHeight ? '20px 20px 40px' : '0px 20px'} />;
  }

  return (
    <Wrapper>
      <RoomEditorTopMenu onSave={onSave} />
      <RoomField padding={isShortHeight ? '20px 20px 40px' : '0px 20px'}>
        <Room ref={roomRef}>
          <Wallpaper
            src={myRoom.wallpaper.img_origin}
            width={Math.round((myRoom.wallpaper.width / 3) * ratio)}
            alt=""
            onContextMenu={(e) => {
              e.preventDefault();
              return false;
            }}
            onDoubleClick={(e) => {
              e.preventDefault();
              return false;
            }}
            data-type="img"
          />
          <Floor
            src={myRoom.floor.img_origin}
            width={Math.round((myRoom.floor.width / 3) * ratio)}
            alt=""
            onContextMenu={(e) => {
              e.preventDefault();
              return false;
            }}
            onDoubleClick={(e) => {
              e.preventDefault();
              return false;
            }}
            data-type="img"
          />
          {myRoom.items.map((item, idx) => (
            <RoomItem
              key={item.id}
              id={item.id}
              type={item.type}
              initialX={item.x}
              initialY={item.y}
              zIndex={idx}
              ratio={ratio}
              img={item.img_origin}
              width={Math.round((item.width / 3) * ratio)}
              height={Math.round((item.height / 3) * ratio)}
              roomRef={roomRef}
              selectedItemId={selectedItemId}
              onRemove={() => onRemoveItem(item.id)}
              onWrite={() => onClickWrite(item.id, item.text)}
              onSelectItem={onSelectItem}
              onMoveEnd={onMoveEnd}
              text={item.text}
            />
          ))}
        </Room>
      </RoomField>

      <RoomEditorBottomMenu
        onCleanRoom={onCleanRoom}
        onClickItemAdd={onClickRoomItemBottomSheet}
        onClickCart={onClickCartRoomItemBottomSheet}
        onLayerUp={onLayerUp}
        onLayerDown={onLayerDown}
        onToggleSidebar={onToggleSidebar}
        showSidebar={showSidebar}
        showLayerControlMenu={selectedItemId !== null}
        itemLength={myRoom.items.length}
        purchaseRequiredCount={uniqueCartRoomItems.length}
        sidebarComponent={
          <RoomEditorSidebar
            ref={sidebarRef}
            addToSidebarItemRefs={addToSidebarItemRefs}
            items={myRoom.items}
            selectedItemId={selectedItemId}
            onSelectItem={onSelectItem}
            onLayerUp={onLayerUp}
            onLayerDown={onLayerDown}
          />
        }
      />
    </Wrapper>
  );
};

const RoomItem = ({
  id,
  type,
  initialX,
  initialY,
  zIndex,
  ratio,
  img,
  width,
  height,
  roomRef,
  selectedItemId,
  onRemove,
  onWrite,
  onSelectItem,
  onMoveEnd,
  text,
}: {
  id: string;
  type: string;
  initialX: number;
  initialY: number;
  zIndex: number;
  ratio: number;
  img: string;
  width: number;
  height: number;
  roomRef: React.RefObject<HTMLDivElement>;
  selectedItemId: string | null;
  onRemove: () => void;
  onWrite: () => void;
  onSelectItem: (id: string) => void;
  onMoveEnd: (id: string, x: number, y: number) => void;
  text?: string;
}) => {
  const speechBubbleRef = useRef<HTMLDivElement>(null);
  const [speechBubbleSize, setSpeechBubbleSize] = useState({ width: 0, height: 0 });
  const isSpeechBubble = useMemo(() => type === '말풍선', [type]);

  const { position, handleTouchStart, handleTouchMove, handleTouchEnd } = useDraggable({
    roomRef,
    width: isSpeechBubble ? speechBubbleSize.width : width,
    height: isSpeechBubble ? speechBubbleSize.height : height,
    ratio,
    initialPosition: {
      x: initialX,
      y: initialY,
    },
  });

  useLayoutEffect(() => {
    if (speechBubbleRef.current) {
      const { width, height } = speechBubbleRef.current.getBoundingClientRect();
      setSpeechBubbleSize({ width, height });
    }
  }, [text, ratio]); // text가 변경될 때 말풍선 크기도 재설정

  const handleStart = (e: React.TouchEvent<HTMLDivElement>) => {
    handleTouchStart(e);
    onSelectItem(id);
  };

  const handleEnd = () => {
    handleTouchEnd();
    onMoveEnd(id, position.x, position.y);
  };

  return (
    <Item
      className="draggable-item"
      onDoubleClick={(e) => e.preventDefault()}
      onTouchStart={handleStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleEnd}
      style={{
        transform: `translate(${Math.round(position.x * ratio)}px, ${Math.round(
          position.y * ratio,
        )}px)`,
        zIndex,
      }}
    >
      {isSpeechBubble ? (
        <>
          <SelectionBox
            type={type}
            isSelected={selectedItemId === id}
            width={speechBubbleSize.width}
            height={speechBubbleSize.height}
            onRemove={onRemove}
            onWrite={onWrite}
          />
          <SpeechBubble
            ref={speechBubbleRef}
            src={img}
            ratio={ratio}
            imgWidth={width}
            imgHeight={height}
            text={text ?? ''}
          />
        </>
      ) : (
        <>
          <SelectionBox
            type={type}
            isSelected={selectedItemId === id}
            width={width}
            height={height}
            onRemove={id.includes('character') ? undefined : onRemove}
          />
          <ItemImg
            src={img}
            width={width}
            onContextMenu={(e) => {
              e.preventDefault();
              return false;
            }}
            onDoubleClick={(e) => {
              e.preventDefault();
              return false;
            }}
            alt=""
            data-type="img"
          />
        </>
      )}
    </Item>
  );
};

export default RoomEditorPage;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 56px 0px 80px;
  -webkit-user-select: none;
  user-select: none;
  touch-action: none;
  overflow-y: auto;
`;

const RoomField = styled.div<{ padding: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 56px - 80px);
  padding: ${({ padding }) => padding};
  touch-action: none;
`;

const Room = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: 100%; /* 가로를 100%로 설정 */
  aspect-ratio: 375 / 488; /* 가로 375px, 세로 488px 비율 */
  height: auto; /* 비율을 유지하면서 자동으로 높이를 설정 */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const Wallpaper = styled.img`
  flex: 1;
`;

const Floor = styled.img`
  flex: 1;
`;

const Item = styled.div`
  position: absolute;
  touch-action: none;
  user-select: none;
  will-change: transform;
`;

const ItemImg = styled.img`
  object-fit: contain;
  touch-action: none;
  user-select: none;
  will-change: transform;
`;
